<template>
    <v-card>
        <v-card-title class="titleIdComponent">
            <h2 class="mb-0" style="width:100%; align-items:center;">
                {{ $t(table.name) }}
                <v-spacer></v-spacer>
                <div v-if="data && data.actions && data.actions.global" >
                    <v-menu v-for="(menu, index) in data.actions.global.menus" :key="'menu_'+index" offset-y class="action-buttons ml-3">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn depressed v-bind="attrs" v-on="on" ripple class="bg-gradient">
                                <v-icon left dark small v-if="menu.icon">${{menu.icon}}</v-icon> {{$t(menu.title)}}
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item v-for="(button, index) in menu.items" :key="'btnMenu_'+index" dense link @click.stop="actionForm(button)">
                                <v-list-item-title class="action-menu">
                                    <v-icon small v-if="button.icon" class="ml-3">${{button.icon}}</v-icon> {{ $t(button.title) }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                    <v-menu v-if="data.actions.global.multi && data.actions.global.multi.length > 0" offset-y left>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="ml-3" color="primary" depressed v-bind="attrs" v-on="on" :disabled="selected.length == 0" ripple>
                                {{$t('multiActionsDatatableLabel')}}
                                <v-icon  class="ml-3">
                                    $expand
                                </v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item v-for="(action, index) in data.actions.global.multi" :key="index" dense link>
                                <v-list-item-title class="action-menu" @click.stop="action.action == 'form' ? actionForm(action, selected) : getLink('multi', action, selected)">
                                    <v-icon small v-if="action.icon" class="mr-3">
                                        ${{action.icon}}
                                    </v-icon>
                                    {{ $t(action.title) }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </h2>
        </v-card-title>
        <v-simple-table v-if="data && data.headers && data.data">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left" v-for="header in data.headers" :key="header.value">
                            {{ header.type !== "actions" ? $t(header.text) : '' }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, key) in data.data" :key="key" :class="item._main_contact ? 'mainContact' : ''">
                        <td v-for="header in data.headers" :key="header.value">
                            <span>
                                <div class="icons-datatable" v-if="header.type == 'actions'" :key="header.value">
                                    <span v-for="(link, idLink) in singleAction" :key="idLink">
                                        <v-tooltip left>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn depressed text class="square" v-bind="attrs" v-on="on" @click.stop="getLink('single', link, item)" :id="idLink" :key="idLink">
                                                    <v-icon dark small>
                                                        ${{link.icon}}
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>{{$t(link.title)}}</span>
                                        </v-tooltip>   
                                    </span>
                                </div>
                                <div v-else-if="header.type == 'object'">
                                    <v-chip v-if="item[header.value].label" color="info" small outlined class="text-uppercase mr-2">
                                        {{item[header.value].label}}
                                    </v-chip>
                                </div>
                                <div v-else-if="header.type == 'array_phone'">
                                    <div @click.stop="dialPhone(phone.number)" v-for="(phone, index) in item[header.value]" :key="index" class="pointer">
                                        <v-chip color="primary" v-if="phone.type" x-small outlined class="text-uppercase mr-2">{{phone.type}}</v-chip>
                                        {{phone.number}}
                                    </div>
                                </div>
                                <div v-else-if="header.type == 'identity'">
                                    <v-avatar size="36px" color="primary">
                                        <v-img :src="api+'/user/'+item.id+'/getAvatar?token='+$store.state.auth.idToken+'&nocache=true'"></v-img>
                                    </v-avatar>
                                    <span class="ml-3">{{ item.firstname }} {{ item.name }}</span>
                                </div>
                                <span v-else>{{ item[header.value] }}</span>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>

        <FormDialogComponent v-if="form" ref="componentForm" :form="form" :action="'SET_DATA'" @refreshData="readDataFromAPI" />
        <ConfirmDialogComponent ref="confirmDelete" />
    </v-card>
</template>

<script>
import GenericDataService from '@/services/GenericDataService';
import FormDialogComponent from "@/components/dialogs/FormDialogComponent";
import ConfirmDialogComponent from '@/components/dialogs/ConfirmDialogComponent';

export default {
    name: "tableComponent",
    components: { FormDialogComponent, ConfirmDialogComponent },
    props: ["table"],
    data() {
        return {
            data: null,
            form: null,
            api:this.$store.state.auth.apiUrl,
            selected: [],
        };
    },
    created() {
        this.readDataFromAPI();
    },
    computed: {
        singleAction:function(){
            if(this.data && this.data.actions && this.data.actions.single && this.data.actions.single.length > 0)
                return this.data.actions.single.filter((e) => !e.default);
        },
    },
    watch: {},
    methods: {
        readDataFromAPI() {
            GenericDataService.getData(this.table.apiUrl).then((response) => {
                if(response.data.success){
                    this.data = response.data.data;
                }
            });
        },
        // méthode pour lancer un appel
        dialPhone(number){
            if(number && number != ''){
                window.location = 'tel:' + number;
            }
        },
        actionForm(item, arrayIds = false) {
            if(item.action == "get"){
                this.$router.push({ path: item.route });
            } else if(item.action == "event"){
                this.$emit(item.event, item.params);
            } else {
                if(!arrayIds){
                    this.getForm(item.url, false, item);
                } else {
                    let ids;
                    if(this.selectedNumber > arrayIds.length){
                        ids= this.selectedIds;
                    } else {
                        ids = arrayIds.map((e) => e.id);
                    }
                    this.getForm(item.url, ids);
                }
            }
        },
        //Action pour getter le form
        getForm(url, arrayIds = false, action = false) {
            let payload = {}
            if(arrayIds){
                payload.ids = arrayIds
            }
            this.formUrl = url
            this.$store.dispatch("forms/GET_FORM_DATA", { url: this.formUrl, payload: payload }).then((e) => {
                this.form = this.$store.state.forms.forms[e.reference];
                setTimeout(() => {
                    this.displayForm(this.form.params.title, action, e.reference);
                }, 100);
            });
        },
        //Affichage du form
        async displayForm(title, action, reference) {
            await this.$refs.componentForm.open(this.$t(title)).then((response) => {
                if(response && response.data && response.data.success) {
                    this.displayDatatableAlert(response);
                    if (response.nextStep) {
                        this.$store.state.forms.forms[reference].schema.steps[Object.keys(this.$store.state.forms.forms[reference].schema.steps)[response.nextStep - 2]].is_validate = true;
                        this.$store.state.forms.forms[reference].schema.current_step = response.nextStep;
                        this.$store.state.forms.forms[reference].model.id = response.data.data.id;
                        this.displayForm(title, action, reference);
                    } else if(action && action.redirect) {
                        if(action.redirect.params)
                            Object.keys(action.redirect.params).map((e) => action.redirect.params[e] = response.data.data[e]);
                        this.$router.push(action.redirect); 
                    } else if(action && action.event) {
                        this.$emit(action.event, response.data.data.id)
                    } else {
                        this.readDataFromAPI();
                    }
                }
            })
        },
        displayDatatableAlert(response){
            this.$store.dispatch('ADD_SNACK', {
                message : this.$t('successActionConfirmed'),
            }).then((e) => {
                this.$store.dispatch("REMOVE_SNACK")
            })
        },
        // recupere l'action a faire selon le type du link
        getLink(type, link, item) {
            if(type == 'single') {
                if(link.action == "get") {
                    let newLink = link.route.replace('{{id}}', item.id)
                    this.$router.push({ path: newLink })
                } else if(link.action == "delete" || link.action == "reinitPass"){
                    this.deleteElement(link, [item.id])
                } else if(link.action == "form") {
                    let newFormUrl = link.url.replace('{{id}}', item.id)
                    this.getForm(newFormUrl, [item.id], link) 
                } else if(link.action == "ajax") {
                    let newUrl = link.url.replace('{{id}}', item.id)
                    GenericDataService.getData(newUrl).then((response) => {
                        this.readDataFromAPI();
                    })
                }
            }
        },
        //effacement d'un ou plusieur objet
        deleteElement(link, arrayIds) {
            if(link.confirm){
                if(link.confirm.checkurl){
                    let linkToCheck = link.confirm.checkurl
                    GenericDataService.postData(linkToCheck, arrayIds).then((response) => {
                        let checkData = response.data.data;
                        let options = {
                            needDoubleValidation: checkData.needDoubleValidation, 
                            confirmationImpacts: checkData.impacts, 
                            confirmationImpactTitle: this.$t(checkData.text),
                            action: link.url,
                            ids: arrayIds
                        }
                        if(typeof checkData.deletable !== 'undefined' && checkData.deletable === false) {
                            options.action = "";
                            options.ids = [];
                            let tmpLink = JSON.parse(JSON.stringify(link));
                            tmpLink.confirm.message = this.$t(checkData.text);
                            this.openConfirmModal(tmpLink, options, arrayIds)
                        } else {
                            this.openConfirmModal(link, options, arrayIds)
                        }
                    })
                } else {
                    let options = {
                        action: link.url,
                        ids: arrayIds
                    }
                    this.openConfirmModal(link, options, arrayIds)
                }
            }
        },
        //ouverture de la modale de confirmation de delete
        async openConfirmModal(link, options){
            if(await this.$refs.confirmDelete.open(this.$t(link.confirm.label), this.$t(link.confirm.message), options)) {
                this.readDataFromAPI();
                if(options.action) {
                    this.$store.dispatch('ADD_SNACK', {
                        message : this.$t('successActionConfirmed'),
                    }).then((e) => {
                        this.$store.dispatch("REMOVE_SNACK")
                    });
                }
            }
        },
    },
};
</script>

<style lang="scss">
    .titleIdComponent {
        border-bottom: 1px solid rgba(0,0,0,0.1)
    }
    tbody {
        tr:hover {
            background-color: transparent !important;
        }
        .pointer:hover {
            cursor: pointer !important;
        }
        .icons-datatable {
            text-align:right;
        }
        tr.mainContact {
            background-color: #e7eff6 !important;
        }
        tr.mainContact:hover {
            background-color: #e7eff6 !important;
        }
    }
</style>